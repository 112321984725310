// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-albums-bowling-green-js": () => import("./../../../src/pages/albums/bowling-green.js" /* webpackChunkName: "component---src-pages-albums-bowling-green-js" */),
  "component---src-pages-albums-heart-of-a-wolf-js": () => import("./../../../src/pages/albums/heart-of-a-wolf.js" /* webpackChunkName: "component---src-pages-albums-heart-of-a-wolf-js" */),
  "component---src-pages-albums-lonnies-lament-js": () => import("./../../../src/pages/albums/lonnies-lament.js" /* webpackChunkName: "component---src-pages-albums-lonnies-lament-js" */),
  "component---src-pages-albums-music-for-velvet-js": () => import("./../../../src/pages/albums/music-for-velvet.js" /* webpackChunkName: "component---src-pages-albums-music-for-velvet-js" */),
  "component---src-pages-albums-perspectives-js": () => import("./../../../src/pages/albums/perspectives.js" /* webpackChunkName: "component---src-pages-albums-perspectives-js" */),
  "component---src-pages-albums-scarpe-js": () => import("./../../../src/pages/albums/scarpe.js" /* webpackChunkName: "component---src-pages-albums-scarpe-js" */),
  "component---src-pages-albums-simply-velvet-js": () => import("./../../../src/pages/albums/simply-velvet.js" /* webpackChunkName: "component---src-pages-albums-simply-velvet-js" */),
  "component---src-pages-albums-stiletto-severinsen-js": () => import("./../../../src/pages/albums/stiletto-severinsen.js" /* webpackChunkName: "component---src-pages-albums-stiletto-severinsen-js" */),
  "component---src-pages-albums-testimony-js": () => import("./../../../src/pages/albums/testimony.js" /* webpackChunkName: "component---src-pages-albums-testimony-js" */),
  "component---src-pages-albums-velvet-js": () => import("./../../../src/pages/albums/velvet.js" /* webpackChunkName: "component---src-pages-albums-velvet-js" */),
  "component---src-pages-bio-js": () => import("./../../../src/pages/bio.js" /* webpackChunkName: "component---src-pages-bio-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-music-js": () => import("./../../../src/pages/music.js" /* webpackChunkName: "component---src-pages-music-js" */),
  "component---src-pages-photos-js": () => import("./../../../src/pages/photos.js" /* webpackChunkName: "component---src-pages-photos-js" */),
  "component---src-pages-play-js": () => import("./../../../src/pages/play.js" /* webpackChunkName: "component---src-pages-play-js" */),
  "component---src-pages-publications-js": () => import("./../../../src/pages/publications.js" /* webpackChunkName: "component---src-pages-publications-js" */),
  "component---src-pages-videos-js": () => import("./../../../src/pages/videos.js" /* webpackChunkName: "component---src-pages-videos-js" */),
  "component---src-templates-blog-template-js": () => import("./../../../src/templates/blogTemplate.js" /* webpackChunkName: "component---src-templates-blog-template-js" */)
}

